import { ref, onBeforeMount } from 'vue-demi'

import { initGrowthBook } from '../growthbook'

export default function useGrowthbook() {
  const growthbook = ref(null)

  onBeforeMount(async () => {
    growthbook.value = await initGrowthBook()
  })

  return growthbook
}
