var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    _vm.$attrs.class,
    _vm.$style.wrapper,
    ( _obj = {}, _obj[_vm.$style.error] = _vm.error, _obj[_vm.$style.disabled] = _vm.disabled, _obj ) ],on:{"click":_vm.handleClick}},[_c('div',{class:_vm.$style.horizontalWrapper},[_vm._t("textarea",function(){
  var _obj, _obj$1;
return [_c('Typography',_vm._g(_vm._b({ref:"textarea",class:[
          _vm.$style.input,
          ( _obj = {}, _obj[_vm.$style.error] = _vm.error, _obj ),
          ( _obj$1 = {}, _obj$1[_vm.$style.hasTopIcon] = _vm.$slots.topIcon, _obj$1 ) ],attrs:{"variant":_vm.TYPOGRAPHY_TYPES.bodyRegular,"tag":"textarea","value":_vm.computedValue}},'Typography',_vm.attrs,false),_vm.listeners))]}),(_vm.$slots.rightIcon || _vm.error)?_c('Typography',{class:[_vm.$style.iconWrapper, _vm.$style.rightIcon]},[_vm._t("rightIcon",function(){return [(_vm.error)?_c('svg',{class:_vm.$style.errorIcon,attrs:{"xmlns":"http://www.w3.org/2000/svg","height":"20px","width":"20px","viewBox":"0 0 20 20","fill":"currentColor","aria-hidden":"true"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z","clip-rule":"evenodd"}})]):_vm._e()]})],2):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }