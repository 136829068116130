/* eslint-disable camelcase */
import urlcat from 'urlcat'

import {
  Customer,
  Pet,
  Prospect,
  ServiceType,
  Subscription,
} from '@front/common/types'
import {
  deltaDate,
  formatDate,
  getActiveServices,
  getServicesByType,
  getServicesTotalPrice,
  isDateValid,
  priceAfterPercentageDiscount,
} from '@front/common/utils'

import { DOG, ISO_LOCALE } from './constants'

export const API_BASE_URL =
  process.env.API_URL || 'https://api.staging.japhy.io'

export const JAPHY_URL = process.env.JAPHY_URL || process.env.VUE_APP_JAPHY_URL

export const PROFILE_BUILDER_URL = `${JAPHY_URL}/profile-builder/`

export const INSTAGRAM_API_URL = 'https://graph.instagram.com/v20.0'

export const AGENDIZE_UNSUBSCRIBE_URL =
  'https://france.agendize.com/web/widget/?c=0BQrNgciuHA_Fob5jCEXkyU5fR03rfQHEg3zsdgVM40%3D'

export const WEBFLOW_JAPHY_URL = `${JAPHY_URL}/sur-mesure`

export const UNSUBSCRIBE_CALL_URL = `${WEBFLOW_JAPHY_URL}/appel-desabonnement`
export const ENGAGEMENT_URL = `${WEBFLOW_JAPHY_URL}/engagements-japhy`
export const SANTE_VET_URL = 'https://blog.japhy.fr/japhy-x-santevet-em' // FIXME: update with JAPHY_URL
export const SPONSORSHIP_URL = 'https://japhy.fr/sur-mesure/parrainage/em'
export const CONFIRM_URL = `${WEBFLOW_JAPHY_URL}/confirmation-paiement`

export function getSanteVetURL(customer: Customer): string {
  const {
    cellphone,
    city,
    civility,
    email,
    firstname,
    lastname,
    pet_birthday,
    pet_breed_id,
    pet_name,
    postal_code,
  } = customer?.flags?.santevet?.email_field || {}

  return urlcat(SANTE_VET_URL, {
    CUSTOMER_EMAIL: email,
    CUSTOMER_NAME: firstname,
    CUSTOMER_LAST_NAME: lastname,
    CUSTOMER_CIVILITE: civility,
    CUSTOMER_CODE_POSTAL: postal_code,
    CUSTOMER_VILLE: city,
    CUSTOMER_TELEPHONE: cellphone,
    PET_1_NAME: pet_name,
    PET_1_BIRTH_DATE: pet_birthday,
    PET_1_BREED: pet_breed_id,
    SOURCE: 'EM',
  })
}

export function getEngagementURL(
  customer: Customer,
  subscription: Subscription,
): string {
  const totalPrice = getServicesTotalPrice(
    getActiveServices(
      getServicesByType(subscription.services, ServiceType.customFood),
    ),
  )
  const engagamentPercentageDiscountAmount = 10
  const subscriptionCartDiscount =
    priceAfterPercentageDiscount(
      totalPrice,
      engagamentPercentageDiscountAmount,
    ) + subscription.shippingFees.amount

  const totalCurrentRenewalAmount =
    totalPrice + subscription.shippingFees.amount

  return urlcat(ENGAGEMENT_URL, {
    SUBSCRIPTION_CART_10_DISCOUNT: `${parseFloat(
      subscriptionCartDiscount,
    ).toFixed(1)}e`,
    TOTAL_CURRENT_RENEWAL_AMOUNT: `${parseFloat(
      totalCurrentRenewalAmount,
    ).toFixed(1)}e`,
    CUSTOMER_ID: customer.id,
    CUSTOMER_EMAIL: customer.email,
    CUSTOMER_NAME: customer.firstName,
    CUSTOMER_LAST_NAME: customer.lastName,
  })
}

export function getConfirmURL(customer: Customer, firstPet: Pet): string {
  function convertBirthObjectToDate(birth): Date {
    if (!birth) {
      return null
    }

    const { birthday, unit, number } = birth

    if (isDateValid(birthday)) {
      return birthday
    }

    const field = {
      year: {
        years: -number,
      },
      month: {
        months: -number,
      },
    }

    return deltaDate(new Date(), field[unit])
  }

  return urlcat(CONFIRM_URL, {
    SOURCE: 'PB',
    CUSTOMER_EMAIL: customer.email,
    CUSTOMER_NAME: customer.firstName,
    CUSTOMER_LAST_NAME: customer.lastName,
    CUSTOMER_CIVILITE: 2,
    CUSTOMER_CODE_POSTAL: customer.deliveryContact.postalCode,
    CUSTOMER_VILLE: customer.deliveryContact.city,
    CUSTOMER_TELEPHONE: customer.deliveryContact.phoneNumber.split('+')[1],
    PET_1_NAME: firstPet.name,
    PET_1_BIRTH_DATE: formatDate(
      convertBirthObjectToDate(firstPet.birth),
      {},
      { locale: ISO_LOCALE },
    ),
    PET_1_BREED: firstPet.species === DOG ? 431 : 247,
  })
}

export function getConfirmParams(customer: Prospect, firstPet: Pet) {
  function convertBirthObjectToDate(birth): Date {
    if (!birth) {
      return null
    }

    const { birthday, unit, number } = birth

    if (isDateValid(birthday)) {
      return birthday
    }

    const field = {
      year: {
        years: -number,
      },
      month: {
        months: -number,
      },
    }

    return deltaDate(new Date(), field[unit])
  }

  return {
    SOURCE: 'PB',
    CUSTOMER_EMAIL: customer.email,
    CUSTOMER_NAME: customer.firstName,
    CUSTOMER_LAST_NAME: customer.lastName,
    CUSTOMER_CIVILITE: 2,
    CUSTOMER_CODE_POSTAL: customer.postalCode,
    CUSTOMER_VILLE: customer.city,
    CUSTOMER_TELEPHONE: customer.phoneNumber.split('+')[1],
    PET_1_NAME: firstPet.name,
    PET_1_BIRTH_DATE: formatDate(
      convertBirthObjectToDate(firstPet.birth),
      {},
      { locale: ISO_LOCALE },
    ),
    PET_1_BREED: firstPet.species === DOG ? 431 : 247,
  }
}
