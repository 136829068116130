var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ClientOnly',[_c('div',{class:_vm.$style.wrapper},[_c('FunnelHeader'),_c('Container',{class:_vm.$style.container},[(
          _vm.$growthBook &&
          _vm.$growthBook.isOn &&
          _vm.$growthBook.isOn('new-funnel-header')
        )?_c('Divider',{class:_vm.$style.divider}):_vm._e(),_c('section',{class:_vm.$style.content,style:(_vm.$growthBook &&
          _vm.$growthBook.isOn &&
          _vm.$growthBook.isOn('new-funnel-header')
            ? {}
            : { marginTop: '20px' })},[_c('Nuxt'),_c('EnvironmentVersion')],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }