/* eslint-disable no-param-reassign */

const initialState = () => ({
  customer: {
    firstName: '',
    lastName: '',
    email: '',
    deliveryContact: {
      countryCode: 'FR',
    },
    company: '',
    country: 'FR',
    promoCode: null,
    registrationCode: null,
  },
})

export default {
  namespaced: true,
  state: initialState,
  getters: {
    get: (state) => state.customer,
  },
  mutations: {
    reset(state) {
      Object.assign(state, initialState())
    },
    update(state, newCustomer) {
      state.customer = newCustomer
    },
  },
}
