<template>
  <component :is="component" />
</template>

<script>
import useGrowthbook from '@front/common/hooks/useGrowthbook'
import { computed, defineComponent } from '@vue/composition-api'

import Default from './components/default.vue'
import New from './components/new.vue'

export default defineComponent({
  setup() {
    const growthbook = useGrowthbook()

    const component = computed(() => {
      return growthbook.value?.isOn?.('new-funnel-header') ? New : Default
    })

    return { component }
  },
})
</script>
